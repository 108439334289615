import request from "@/utils/request";

// 专业课学习统计
export const getCoursepPublicDemandAccount = (data) => {
  return request({
    url: "/foreground/course/userTrainingPlanTj",
    method: "GET",
    data: data,
    noLoading: true,
  });
};
// 专业课学习列表
export const getCoursepUserCourseList = (data) => {
  return request({
    url: "/foreground/course/userCourseList",
    method: "GET",
    data: data,
    noLoading: true,
  });
};
// 专业课学习详情
export const getCourseGetUserCourseByIDList = (data) => {
  return request({
    url: "/foreground/testTitle/getUserCourseByIDList",
    method: "GET",
    data: data,
    noLoading: true,
  });
};

// 专业课学习星级评价
export const getCourseCurriculumEvaluation = (data) => {
  return request({
    url: "/foreground/curriculumEvaluation/curriculumEvaluation",
    method: "POST",
    data: data,
    noLoading: true,
  });
};

// 专业课学习视频开始播放
export const getStudyProcessStart = (data) => {
  return request({
    url: "/foreground/studyProcess/add",
    method: "POST",
    data: data,
    noLoading: true,
  });
};
// 专业课学习视频进度上传
export const getStudyProcessUpdate = (data) => {
  return request({
    url: "/foreground/studyProcess/edit",
    method: "POST",
    data: data,
    noLoading: true,
  });
};
// 专业课学习视频进度结束
export const getStudyProcessEnd = (data) => {
  return request({
    url: "/foreground/studyProcess/end",
    method: "POST",
    data: data,
    noLoading: true,
  });
};
// 获取考试题目
export const getStudyTestTitleList = (data) => {
  return request({
    url: "/foreground/testTitle/studyTestTitleList",
    method: "GET",
    data,
    noLoading: true,
  });
};
// 提交试卷
export const postStudyTestTitleSend = (data) => {
  return request({
    url: "/foreground/testTitle/studyTestTitleSend",
    method: "GET",
    data,
    noLoading: true,
  });
};
// 纠错查看
export const getErrorStudyTestTitleList = (data) => {
  return request({
    url: "/foreground/testTitle/errorStudyTestTitleList",
    method: "GET",
    data,
    noLoading: true,
  });
};
// 查看答案
export const getTrueStudyTestTitleList = (data) => {
  return request({
    url: "/foreground/testTitle/trueStudyTestTitleList",
    method: "GET",
    data,
    noLoading: true,
  });
};
